/* unplugin-vue-components disabled */var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',_vm._b({ref:"table",attrs:{"table-id":"rating_categories","id":_vm.id,"columns":_vm.columns,"loader":_vm.tableLoader,"loaderConfig":_vm.reportSettings,"persist-settings":false,"selectable":!_vm.small,"item-id":"c_id","sticky-columns":_vm.small ? 0 : 2},on:{"selectAction":function ($event) { return $event.addToList({
                entity: 'category',
                mp: this$1.reportSettings.mp,
            }); }},scopedSlots:_vm._u([{key:"c_name_path",fn:function(ref){
            var item = ref.item;
            var categories = ref.categories;
return [_c('category-path',{attrs:{"block":"","path":item.c_id_path,"categories":categories,"route-params":_vm.routeParams,"target":_vm.$route.name === 'Rating' || _vm.$route.name === 'Dashboard'
                    ? '_blank'
                    : null,"route-query":{
                fbs: _vm.reportSettings.fbs,
                date: _vm.reportSettings.date,
                date2: _vm.reportSettings.date2,
            }}})]}}])},'data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }