//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import CategoryPath from "@/components/Category/Path.vue";
import { getEntityTableColumns } from "@/utils/enums";
export default {
  components: {
    DataTable,
    CategoryPath
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      if (this.small) {
        query.page_size = 5;
      }
      return this.$store.dispatch(this.action, {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      let pos = 0;
      let columns = [{
        title: "№",
        position: pos++,
        show: true,
        name: "index",
        width: 50,
        type: "number"
      }, {
        title: "Категория",
        position: pos++,
        show: true,
        name: "c_name_path",
        width: 440,
        filter: "text",
        type: "slot"
      }, ...getEntityTableColumns("category", 3, this.reportSettings.metric)];

      //&: не выводим 'Упущенная выручка'
      let idx = columns.findIndex(c => c.name === "losses");
      if (idx > -1) columns.splice(idx, 1);
      if (this.small) {
        columns = columns.filter(item => item.name === "c_name_path" || item.name === "revenue");
      }
      return columns;
    }
  }
};