//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import ProductCategories from "@/components/Product/Categories";
//import { getEntityTableColumns } from "@/utils/enums";
//! import { dateRangeDays } from "@/utils/dates";
export default {
  components: {
    DataTable,
    ProductCategories
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      if (this.small) {
        query.page_size = 5;
      }
      return this.$store.dispatch(this.action, {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      //! const days = dateRangeDays(
      //     this.reportSettings.date,
      //     this.reportSettings.date2
      // );

      let columns = [{
        title: "№",
        show: true,
        name: "index",
        width: 50,
        type: "number"
      }, {
        title: "Товар",
        show: true,
        name: "title",
        width: 380,
        filter: "text",
        type: "link",
        noOverflow: true,
        getImage: item => item.image,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                ...this.routeParams,
                p_id: item.p_id
              },
              query: {
                fbs: this.reportSettings.fbs,
                date: this.reportSettings.date,
                date2: this.reportSettings.date2
              }
            },
            text: item.title,
            target: this.$route.name === "Rating" || this.$route.name === "Dashboard" ? "_blank" : null
          };
        }
      }, {
        title: "Категория",
        show: true,
        name: "category",
        width: 380,
        filter: "select",
        type: "slot"
      }, {
        title: "Позиция",
        show: true,
        name: "position",
        type: "number",
        width: 100,
        filter: "numberRange"
      },
      //{{kwd ~ /src/components/Brand/ProductsTable.vue
      {
        title: "Кол-во запросов",
        show: true,
        name: "keywords",
        width: 100,
        filter: "numberRange",
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                p_id: item.p_id,
                tab: "keywordsPositions"
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.keywords,
            target: "_blank"
          };
        }
      },
      //}}kwd

      {
        title: "SKU",
        show: true,
        name: "sku",
        width: 100,
        filter: "text",
        type: "link",
        getLink(item) {
          return {
            href: item.mp_url,
            text: item.sku
          };
        }
      },
      /*
      //!
      // {
      //     title: `Отзывов за ${days} дней`,
      //     show: true,
      //     name: "new_reviews",
      //     type: "number",
      //     width: 100,
      //     filter: "numberRange",
      // },
      */

      // ...getEntityTableColumns("product", 4,
      //     this.reportSettings.metric
      // ),

      {
        name: 'revenue',
        title: "Выручка",
        type: "money"
      }, {
        name: 'sold',
        title: "Продажи (шт)",
        type: "number"
      }];
      if (this.reportSettings.mp == "wb") {
        columns = columns.concat([{
          //title: "Цена",
          title: "Цена с WB кошельком",
          //wb-no-spp
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        },
        /*
        {
            title: "СПП",
            show: true,
            name: "price_discount_spp",
            type: "percent",
            width: 100,
            filter: "numberRange",
        },
        */
        {
          //title: "Базовая цена",
          title: "Цена",
          //wb-no-spp
          show: true,
          name: "price_spp",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Скидка",
          show: true,
          name: "discount_wb",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }]);
      } else {
        columns = columns.concat([{
          title: "Цена",
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Скидка",
          show: true,
          name: "discount",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }]);
      }
      columns = columns.concat([{
        title: "Вес, г",
        name: "p_weight",
        type: "number",
        width: 100,
        filter: "numberRange",
        show: true
      }]);
      if (this.reportSettings.mp == "ozon") {
        columns = columns.concat([{
          title: "Цена продавца",
          name: "p_true_seller_price",
          type: "money",
          width: 100,
          filter: "numberRange",
          show: true
        }, {
          title: "Процент выкупа, %",
          name: "p_delivered_percent",
          type: "percent",
          width: 100,
          filter: "numberRange",
          show: true
        }]);
      }
      columns = columns.concat([{
        name: 'remains',
        title: "Текущий остаток (шт)",
        type: "number"
      }, {
        //name: 'p_reviews',
        name: 'reviews',
        title: "Отзывы",
        type: "number"
      }, {
        name: 'rating',
        title: "Рейтинг",
        type: "number"
      }, {
        name: "categories",
        title: "Кол-во категорий",
        type: "number"
      }, {
        name: "keywords",
        title: "Поисковые запросы",
        type: "number"
      }, {
        name: "avg_sold",
        title: "Сред. продаж в день (шт)",
        type: "number"
      }, {
        name: "avg_revenue",
        title: "Сред. выручка в день"
      }, {
        name: "days",
        title: "Дней в наличии",
        type: "number"
      }, {
        name: "losses",
        title: "Упущенная выручка"
      }]);
      columns = columns.map((el, i) => {
        el.position = i + 1;
        if (el["type"] == undefined) {
          el.type = "money";
        }
        if (el["filter"] == undefined) {
          el.filter = "numberRange";
        }
        if (el["show"] == undefined) {
          el.show = true;
        }
        if (el["width"] == undefined) {
          el.width = 120;
        }
        return el;
      });
      if (this.small) {
        columns = columns.filter(item => item.name === "title" || item.name === "revenue");
      }
      return columns;
    }
  }
};